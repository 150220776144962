"use client";
import { cn } from "@/utilities/cn";
import Link from "next/link";
import { useState } from "react";
import { Button } from "../ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
} from "../ui/sheet";
import Logo from "./Logo";
import Text from "../text";
import { appRoutes } from "@/data/app-routes";

export default function Header() {
  return (
    <div className="bg-background shadow sticky top-0 w-full z-[51] h-20">
      <div className="flex items-center justify-between gap-8 container py-4">
        <Link aria-label="Return to home" href="/">
          <Logo className="h-12" />
        </Link>

        <div className="hidden md:flex items-center gap-4">
          <div className="flex items-center tracking-[0.15rem] text-sm font-header uppercase text-foreground">
            {appRoutes.filter((f) => !f.hidden).map((route, idx) => (
              <Link
              key={idx}
                aria-label={route.arialLabel}
                href={route.path}
                className="px-4 py-2"
              >
                {route.name}
              </Link>
            ))}
          </div>
          <Button aria-label="Contact us now" asChild>
            <Link href="/contact">Contact Us</Link>
          </Button>
        </div>
        <MobileNavigation />
      </div>
    </div>
  );
}

function MobileNavigation() {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger
        aria-label="Open mobile navigation"
        className="block md:hidden"
      >
        <div className="relative grid gap-1">
          <div
            className={cn(
              "h-[1px] w-5 bg-foreground duration-500",
              open && "absolute -rotate-[315deg]"
            )}
          />
          <div
            className={cn(
              "h-[1px] w-5 bg-foreground duration-100",
              open ? "rotate-180 opacity-0" : "opacity-100"
            )}
          />
          <div
            className={cn(
              "h-[1px] w-5 bg-foreground duration-500",
              open && "absolute rotate-[315deg]"
            )}
          />
        </div>
      </SheetTrigger>
      <SheetContent side="top" className="top-20">
        <SheetTitle className="hidden" />
        <SheetDescription className="hidden" />
        <div className="grid">
          {appRoutes.map((route, idx) => (
            <SheetClose key={idx} asChild>
              <Link
                aria-label={route.arialLabel}
                href={route.path}
                className="px-4 py-2"
              >
                <Text variant="cardHeading">{route.name}</Text>
              </Link>
            </SheetClose>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
}
